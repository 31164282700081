/* eslint-disable */
import React from 'react';

const InstagramIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.4651 1H5.48836C3.00951 1 1 3.00951 1 5.48836V14.4651C1 16.9439 3.00951 18.9534 5.48836 18.9534H14.4651C16.9439 18.9534 18.9534 16.9439 18.9534 14.4651V5.48836C18.9534 3.00951 16.9439 1 14.4651 1Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.5672 9.41105C13.678 10.1581 13.5504 10.9211 13.2026 11.5915C12.8547 12.2619 12.3044 12.8055 11.6298 13.1451C10.9551 13.4846 10.1906 13.6028 9.445 13.4828C8.69935 13.3628 8.01052 13.0108 7.47648 12.4767C6.94244 11.9427 6.5904 11.2539 6.47041 10.5082C6.35043 9.76253 6.46861 8.99802 6.80816 8.3234C7.14771 7.64878 7.69133 7.09841 8.3617 6.75056C9.03207 6.40271 9.79506 6.27511 10.5421 6.38589C11.3042 6.49889 12.0097 6.85399 12.5544 7.39874C13.0991 7.94349 13.4542 8.64899 13.5672 9.41105Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.9141 5.03943H14.9241" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

// eslint-disable-next-line import/no-default-export
export default InstagramIcon;
